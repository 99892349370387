import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../../components/Layout'
import IconList from '../../components/accommodations/IconList'

import getBangaloData from '../../messages/bangalo-messages'
import getCasaData from '../../messages/casa-messages'
import { getAccommodationsMessages } from '../../messages/components-messages'

import {
    CASA_PATH,
    BANGALO_PATH,
} from '../../utils/routes';

const Landing = () => {
    const casaData = getCasaData()
    const bangaloData = getBangaloData()
    const accommodationMessages = getAccommodationsMessages()

    return (
        <Layout>
            <Helmet>
                <title>{accommodationMessages.title}</title>
                <meta name="description" content="Accommodations" />
            </Helmet>


            <div id="main">
                <section id="description" className="accommodations-title">
                    <div className="inner">
                        <header className="major">
                            <h2>{accommodationMessages.title}</h2>
                        </header>
                        <span>{accommodationMessages.description}</span>
                    </div>
                </section>
                <section className="spotlights">
                    <section className="accommodation-summary">
                        <Link to="/acomodacoes/bangalo-do-soter" className="image">
                            <img src={bangaloData.coverImage} alt="" />
                        </Link>
                        <div className="content">
                            <div>
                                <header className="major">
                                    <h3>{bangaloData.title}</h3>
                                </header>
                                <IconList minPeople={bangaloData.minPeople} maxPeople={bangaloData.maxPeople}/>
                                <ul className="actions">
                                    <li><Link to={BANGALO_PATH} className="button">{accommodationMessages.moreInfo}</Link></li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section className="accommodation-summary-alt">
                        <Link to="/acomodacoes/casa-do-soter" className="image">
                            <img src={casaData.coverImage} alt="" />
                        </Link>
                        <div className="content">
                            <div className="inner">
                                <header className="major">
                                    <h3>{casaData.title}</h3>
                                </header>
                                <IconList minPeople={casaData.minPeople} maxPeople={casaData.maxPeople} pool={false} sauna={false} wood_oven={true}/>
                                <ul className="actions">
                                    <li><Link to={CASA_PATH} className="button">{accommodationMessages.moreInfo}</Link></li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </section>
            </div>

        </Layout>
    )
}

export default Landing